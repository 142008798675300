import React from 'react'
import Icon from '@mdi/react'
import Logo from '../assets/temp-cat.svg'

import '../styles/tip-jar1.css'

const TipJar = () => {
  return (
    <div className="tip-jar">
      <div className="tip-jar-main">
        <div className="tip-jar-text">Hey Man! Drop some lagniappe in the Tip Jar!</div>
        <form 
          className="tip-jar-form"
          action="https://www.paypal.com/donate" 
          method="post" 
          target="_top"
        >
          <input 
            type="hidden" 
            name="hosted_button_id" 
            value="YHFHCN7H5C8VN" 
          />
          <input 
            className="paypal-button" 
            type="image" 
            src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" 
            border="0" 
            name="submit" 
            title="PayPal - The safer, easier way to pay online!" 
            alt="Donate with PayPal button"
          />
          <img 
            alt="" 
            border="0" 
            src="https://www.paypal.com/en_US/i/scr/pixel.gif" 
            width="1" 
            height="1" 
          />
        </form>
      </div>
      <Logo className="tip-jar-logo"/>
    </div>
  )
}

export default TipJar
import React from 'react'
import Icon from '@mdi/react'
import { mdiEmailNewsletter,  } from '@mdi/js'

import '../styles/email-subscribe.css'


const EmailSubscribe = () => {
  return (
    <div 
      className="email-subscribe"
    >
      <a href="https://landing.mailerlite.com/webforms/landing/z3v3v2" target='_blank' rel="noopener noreferrer">
        <div className="email-subscribe-icon">
          <Icon
            path={mdiEmailNewsletter}
            size={1.5}
          />
        </div>
        <div className="email-subscribe-text">
          <div>Subscribe to my weekly newsletter!</div>
        </div>
      </a>
    </div>
  )
}

export default EmailSubscribe
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import Monetizer from '../components/monetizer'
import SEO from "../components/seo"

import "../styles/bio.css"

const BioPage = ({ data }) => {
  const bio = data.strapiCmbBio

  return (
    <Layout>
      <SEO title="About" />
      <div className="cmb-bio">
        <div className="cmb-bio-hero">
          <GatsbyImage 
            className="cmb-bio-hero-pic"
            image={getImage(bio.cmb_bio_hero.localFile)} 
            loading="eager"
            placeholder="dominantColor"
            alt=""
          />
          <h1 className="cmb-bio-title">{bio.title}</h1>
        </div>
        <div className="cmb-bio-container">
          <Monetizer />
          <div className="cmb-bio-main">
            <GatsbyImage
              imageClass="cmb-bio-image"
              image={getImage(bio.bio_image.localFile)}
              alt=""
            />
            <div className="cmb-bio-body">
              <ReactMarkdown children={bio.bio} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    strapiCmbBio {
      title
      cmb_bio_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
            )
          }
        }
      }
      bio_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
            )
          }
        }
      }
      bio
    }
  }
`

export default BioPage
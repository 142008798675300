import React from 'react'

import TipJar from '../components/tip-jar'
import EmailSubscribe from '../components/email-subscribe'

import '../styles/monetizer.css'

const Monetizer = () => {
  return (
    <div className="monetizer">
      <TipJar />
      <EmailSubscribe />
    </div>
  )
}

export default Monetizer
